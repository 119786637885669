import { Button } from '@rescui/button';
import { Dropdown } from '@rescui/dropdown';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { CHANGE_PARAMETER_VALUE } from '../redux/actionTypes';
import { connect } from 'react-redux';
import { FilterIcon } from '@rescui/icons';
import './styles/ktor-options.css';
import { Select } from '@rescui/select';
import { valueToSelectOption } from '../services/formUtils';
import { Toggle } from '@rescui/toggle';
import { createTextCn } from '@rescui/typography';
import cn from 'classnames';
import KtorLogo from '../KtorLogo';
import { Checkbox } from '@rescui/checkbox';
import BuildSystemArgs from '../constants/buildSystemArgs';

const KtorOptions = ({
  buildSystem,
  buildSystemArgs,
  ktorVersion,
  engine,
  configurationIn,
  addSampleCode,
  settingsOptions,
  changeParameter,
  flatten,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const textCn = createTextCn(theme);
  const toggleForm = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  const {
    buildSystem: buildSystemOptions,
    ktorVersion: ktorVersionOptions,
    engine: engineOptions,
    configurationIn: configurationInOptions,
  } = settingsOptions;
  const labelCn = cn(textCn('rs-text-2', { hardness: 'hard' }));
  const changeBuildSystemArg = (key, value) => changeParameter('buildSystemArgs', {
    ...Object.keys(buildSystemArgs)
        .filter((key) => BuildSystemArgs.VALID_ARGS.has(key))
        .reduce((obj, key) => {
          obj[key] = buildSystemArgs[key];
          return obj;
        }, {}),
    [key]: value,
  });

  return (
    <div className={cn('ktor-options', { flatten })}>
      <div className="ktor-options__summary rs-text-3 rs-text-3_hardness_hard">
        <KtorLogo height="18px" />
        <span>{ktorVersion}</span>
        <span className="engine">{engine}</span>
      </div>

      <Dropdown
        trigger={
          <Button
            icon={<FilterIcon />}
            iconPosition="right"
            size="s"
            mode={ flatten ? 'clear' : 'outline' }
            onClick={toggleForm}
          >
            { 'Configure' }
          </Button>
        }
        isOpen={isOpen}
      >
        <form className="ktor-options__content rs-text-2 rs-text-2_hardness_hard">

          <label className={labelCn} htmlFor="build-system">
            Build system
          </label>
          <Select
            id="build-system"
            size="s"
            theme={theme}
            placeholder="Choose a build system..."
            value={valueToSelectOption(buildSystem, buildSystemOptions)}
            onChange={(e) => changeParameter('buildSystem', e.value)}
            options={buildSystemOptions}
            note={
              buildSystem.startsWith('GRADLE') ? (
                <Checkbox
                  size="s"
                  checked={buildSystemArgs[BuildSystemArgs.CATALOG_ARG]}
                  onChange={() => changeBuildSystemArg(BuildSystemArgs.CATALOG_ARG, !buildSystemArgs[BuildSystemArgs.CATALOG_ARG])}
                >
                  Use version catalog
                </Checkbox>
              ) : []
            }
          />


          <label className={labelCn} htmlFor="ktor-version">
            Ktor version
          </label>
          <Select
            id="ktor-version"
            size="s"
            theme={theme}
            placeholder="Choose ktor version..."
            value={valueToSelectOption(ktorVersion, ktorVersionOptions)}
            onChange={(e) => changeParameter('ktorVersion', e.value)}
            options={ktorVersionOptions}
          />

          <label className={labelCn} htmlFor="engine">
            Engine
          </label>
          <Select
            id="engine"
            size="s"
            theme={theme}
            placeholder="Choose engine..."
            value={valueToSelectOption(engine, engineOptions)}
            onChange={(e) => changeParameter('engine', e.value)}
            options={engineOptions}
            note={
              <div className="project-settings__note">
                If unsure, pick the default option
              </div>
            }
          />

          <label className={labelCn} htmlFor="configuration-in">
            Configuration
          </label>
          <Select
            id="configuration-in"
            size="s"
            theme={theme}
            placeholder="Choose configuration..."
            value={valueToSelectOption(configurationIn, configurationInOptions)}
            onChange={(e) => changeParameter('configurationIn', e.value)}
            options={configurationInOptions}
          />

          <div className="ktor-options__footer">
            <div>
              <Toggle
                theme={theme}
                checked={addSampleCode}
                onChange={(e) => changeParameter('addSampleCode', e.target.checked)}
              >
                Include samples
              </Toggle>
            </div>
            <Button
              mode="rock"
              size="s"
              onClick={toggleForm}
            >
              Done
            </Button>
          </div>
        </form>
      </Dropdown>
    </div>
  );
};

const optionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

KtorOptions.propTypes = {
  buildSystem: PropTypes.string,
  buildSystemArgs: PropTypes.object,
  website: PropTypes.string,
  ktorVersion: PropTypes.string,
  engine: PropTypes.string,
  configurationIn: PropTypes.string,
  addSampleCode: PropTypes.bool,
  addedFeatures: PropTypes.arrayOf(PropTypes.string),
  settingsOptions: PropTypes.shape({
    buildSystem: PropTypes.arrayOf(optionType),
    ktorVersion: PropTypes.arrayOf(optionType),
    engine: PropTypes.arrayOf(optionType),
    configurationIn: PropTypes.arrayOf(optionType),
  }),
  settingsLoaded: PropTypes.bool,
  theme: PropTypes.string,
  changeParameter: PropTypes.func.isRequired,
  flatten: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => ({
  changeParameter: (name, value) =>
    dispatch({
      type: CHANGE_PARAMETER_VALUE,
      payload: { name, value },
    }),
});

const mapStateToProps = ({ projectConfig, options, settingsLoaded, theme }) => ({
  buildSystem: projectConfig.buildSystem,
  buildSystemArgs: projectConfig.buildSystemArgs || {},
  ktorVersion: projectConfig.ktorVersion,
  engine: projectConfig.engine,
  configurationIn: projectConfig.configurationIn,
  settingsOptions: options,
  addSampleCode: projectConfig.addSampleCode,
  theme: theme,
  settingsLoaded,
});

export default connect(mapStateToProps, mapDispatchToProps)(KtorOptions);

