import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { START_PROJECT_PREVIEW } from '../redux/actionTypes';
import { Menu } from '@rescui/menu';
import './styles/preview-panel.css';
import MarkdownCode from './MarkdownCode';
import FileNavItem from './FileNavItem';
import { createDirectories, directoriesFirstEntries, flattenDirectories, formatPath } from '../services/directories';
import Loader from '@jetbrains/ring-ui-built/components/loader/loader';
import { useTextStyles } from '@rescui/typography';
import cn from 'classnames';

export function fileContentsSkipped(code) {
  return /^\(ignored/.test(code);
}

// Pick the first file in the project, with preference for Application.kt.
function selectFirstFile(previewProject) {
  const keys = Object.keys(previewProject);
  const firstFile = keys.find((key) => key.includes('Application.kt')) || keys[0];
  return firstFile ? { [firstFile]: previewProject[firstFile] } : {};
}

const PreviewFileContents = ({ fileName, contents, theme }) => {
  const textCn = useTextStyles();
  return (
    <>
      <h3 className={textCn('rs-text-3', { hardness: 'hard' })}>{formatPath(fileName)}</h3>
      <div className={cn('preview-project-panel__file-contents', textCn('rs-text-2'))}>
        { contents ? (
          <MarkdownCode
            code={'```\n' + contents + '\n```'}
            theme={theme}
          />
        ) : (
          <p className="rs-text-2">
            This file is not available for preview.
          </p>
        )
        }
      </div>
    </>
  );
};

PreviewFileContents.propTypes = {
  fileName: PropTypes.string,
  contents: PropTypes.string,
  theme: PropTypes.string,
};

const ProjectPreview = ({
  visible,
  projectConfigString,
  previewProject,
  generatePreview,
  theme,
}) => {
  const previewFormatted = useMemo(
      () => {
        const withDirs = createDirectories(previewProject);
        const flattened = flattenDirectories(withDirs);
        return directoriesFirstEntries(flattened);
      },
      [previewProject],
  );
  const [selectedFiles, setSelectedFiles] = useState(selectFirstFile(previewProject));
  const [lastConfigString, setLastConfigString] = useState(projectConfigString);

  // we'll only fetch the preview when the tab is visible (and it is changed)
  useEffect(() => {
    if (visible && (projectConfigString !== lastConfigString || Object.keys(previewProject).length === 0)) {
      generatePreview();
      setLastConfigString(projectConfigString);
    }
  }, [visible, projectConfigString]);

  // ensure there is always a selected file when the preview changes
  useEffect(() => {
    if (!previewProject[selectedFiles]) {
      setSelectedFiles(selectFirstFile(previewProject));
    }
  }, [previewProject]);

  return (
    <div style={{ display: visible ? undefined : 'none' }} className="preview-project-panel">
      {previewProject ?
        (
          <div className="preview-project-panel__files">
            <Menu className="preview-project-panel__nav" size="s">
              {previewFormatted.map(([key, value]) => (
                <FileNavItem
                  key={key}
                  path={key}
                  name={key}
                  value={value}
                  selectedFiles={selectedFiles}
                  onSelect={setSelectedFiles}
                />
              ))}
            </Menu>
            <div className="preview-project-panel__file-preview">
              {
                Object.entries(selectedFiles).map(([fileName, contents]) => typeof contents === 'string' && (
                  <PreviewFileContents
                    key={fileName}
                    fileName={fileName}
                    contents={contents}
                    theme={theme}
                  />
                ))
              }
            </div>
          </div>
        ) : <Loader />
      }
    </div>
  );
};

ProjectPreview.propTypes = {
  visible: PropTypes.bool,
  projectConfigString: PropTypes.string,
  previewProject: PropTypes.object,
  generatePreview: PropTypes.func,
  theme: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  generatePreview: () => dispatch({ type: START_PROJECT_PREVIEW }),
});

const mapStateToProps = ({
  projectConfig,
  previewProject,
  theme,
}) => ({
  projectConfigString: Object.entries(projectConfig)
      .map(([key, value]) => `${key} = ${value}`)
      .join(', '),
  previewProject: previewProject || {},
  theme,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPreview);
