import {
  FINISH_PROJECT_GENERATING,
  SHOW_ALERT,
  START_PROJECT_GENERATING,
} from '../actionTypes';

import { makeErrorAlert } from '../../services/helper';
import fetchApi from '../../services/fetchApi';
import download from 'downloadjs';
import { trackError } from '../../constants/analytics';

export default function generateProject({ dispatch, getState }) {
  const finishGenerating = (data) =>
    dispatch({ type: FINISH_PROJECT_GENERATING, payload: data });

  const onError = (errorMessage) =>
    dispatch({
      type: SHOW_ALERT,
      payload: { alert: makeErrorAlert(errorMessage) },
    });

  const processGeneratedFile = ({ blob, fileName }) => {
    download(blob, fileName, 'application/zip');
    return finishGenerating({ blob, fileName });
  };

  return (next) => (action) => {
    if (action.type === START_PROJECT_GENERATING) {
      const { projectConfig, generatedProject } = getState();

      if (generatedProject && generatedProject.blob) {
        return processGeneratedFile(generatedProject);
      }

      const {
        artifact,
        website,
        name,
        ktorVersion,
        kotlinVersion,
        buildSystem,
        buildSystemArgs,
        engine,
        configurationIn,
        addSampleCode,
      } = Object.keys(projectConfig).reduce((result, key) => {
        const param = projectConfig[key];
        return {
          ...result,
          [key]: (param.id ? param.id : param.value) || param,
        };
      }, {});

      const data = {
        settings: {
          ['project_name']: artifact,
          ['company_website']: website,
          ['ktor_version']: ktorVersion,
          ['kotlin_version']: kotlinVersion,
          ['build_system']: buildSystem,
          ['build_system_args']: buildSystemArgs,
          engine,
        },
        features: projectConfig.plugins,
        configurationOption: configurationIn,
        addDefaultRoutes: addSampleCode,
        addWrapper: true,
      };

      fetchApi(
          '/project/generate',
          {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          },
          'blob',
      )
          .then((blob) => {
            const fileName = `${name}.zip`;
            return processGeneratedFile({ blob, fileName });
          }, ({ message, status }) => {
            finishGenerating({});
            trackError(`generating-${status}`, true);
            return onError(`Failed to generate project. ${message}`);
          });
    }

    return next(action);
  };
}
