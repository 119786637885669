import {
  ADD_TEMPLATE,
  CHANGE_PARAMETER_VALUE,
  FINISH_PROJECT_GENERATING,
  FINISH_PROJECT_PREVIEW,
  HIDE_ALERT,
  LOAD_FEATURE_DESCRIPTOR,
  LOAD_FEATURE_INFO,
  LOAD_FEATURES,
  LOAD_SETTINGS,
  REMOVE_TEMPLATE,
  SEARCH,
  SHOW_ALERT,
  SHOW_FEATURE_INFO,
  START_LOAD_FEATURES,
  SWITCH_PARAMETERS_MODE,
  TOGGLE_THEME,
  UPDATE_LOCATION,
  UPDATE_SERIALIZATION_MODEL,
} from '../actionTypes';

import { makeArtifactName } from '../../services/helper';
import { createSerializationModel } from '../../services/serializationModels';
import { backendAssetUrl } from '../../services/fetchApi';
import { templateModels } from '../../services/projectTemplates';

export const initialState = {
  projectConfig: {
    website: 'com.example',
    name: 'ktor-sample',
    artifact: 'com.example.ktor-sample',
    kotlinVersion: '',
    ktorVersion: '',
    buildSystem: '',
    buildSystemArgs: {},
    engine: '',
    configurationIn: 'YAML',
    addSampleCode: true,
    plugins: [],
  },
  stage: null,
  features: {},
  templates: [...templateModels],
  docs: {},
  descriptors: {},
  options: {
    kotlinVersion: [],
    ktorVersion: [],
    buildSystem: [],
    engine: [],
    configurationIn: [],
  },
  selectedFeatureId: '',
  parametersMode: 'view',
  settingsLoaded: false,
  featuresLoaded: false,
  previewProject: undefined,
  generatedProject: {},
  alerts: [],
  setParams: undefined,
  serializationModel: createSerializationModel(),
  theme: (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light',
  search: {
    searchText: '',
    vendor: null,
    category: null,
  },
};

function defaultOptionToParam(obj, known) {
  if (known) {
    return known.id || known;
  }
  const defaultOption = obj.options.find(({ id }) => id === obj.default_id);
  return !defaultOption && obj.options[0] ?
    obj.options[0].id :
    defaultOption.id;
}

function defaultToParam(obj, known) {
  return known || (obj && obj.default) || '';
}

function toOptionList(obj, fieldName) {
  return obj[fieldName].options.map(({ id, name }) => ({
    id,
    value: id,
    label: name,
  }));
}

// eslint-disable-next-line complexity
export default function rootReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case LOAD_SETTINGS: {
      const { artifact, buildSystem, engine, configurationIn } =
        state.projectConfig || {};

      const website = defaultToParam(
          payload.company_website,
          state.projectConfig?.website,
      );
      const name = defaultToParam(
          payload.project_name,
          state.projectConfig?.name,
      );

      const buildSystemNew = defaultOptionToParam(
          payload.build_system,
          buildSystem,
      ) || 'GRADLE_KTS';

      return {
        ...state,
        settingsLoaded: true,
        projectConfig: {
          ...(state.projectConfig || {}),
          website,
          name,
          artifact: defaultToParam(makeArtifactName(website, name), artifact),
          kotlinVersion: defaultOptionToParam(payload.kotlin_version),
          ktorVersion: defaultOptionToParam(payload.ktor_version),
          buildSystem: buildSystemNew,
          engine: defaultOptionToParam(payload.engine, engine),
          configurationIn: defaultOptionToParam(
              payload.configuration_in,
              configurationIn,
          ),
          addSampleCode: true,
        },
        options: {
          kotlinVersion: toOptionList(payload, 'kotlin_version'),
          ktorVersion: toOptionList(payload, 'ktor_version').reverse(),
          buildSystem: toOptionList(payload, 'build_system'),
          engine: toOptionList(payload, 'engine'),
          configurationIn: toOptionList(payload, 'configuration_in'),
        },
        ktorDefaultVersionId: payload.ktor_version.default_id,
      };
    }

    case START_LOAD_FEATURES: {
      return {
        ...state,
        featuresLoaded: false,
      };
    }

    case LOAD_FEATURES: {
      const addIsRequiredForFeatures = (featuresMap) => {
        const featuresKeys = Object.keys(featuresMap);
        return featuresKeys.reduce((result, featureKey) => {
          const isRequiredForFeatures = featuresKeys.filter(
              (key) => featuresMap[key].requiredFeatures.indexOf(featureKey) > -1,
          );
          return {
            ...result,
            [featureKey]: {
              ...featuresMap[featureKey],
              isRequiredForFeatures,
            },
          };
        }, {});
      };

      const resFeatures = addIsRequiredForFeatures(
          payload.reduce((result, feature) => {
            const {
              xmlId,
              name,
              description,
              version,
              vendor,
              vendorUrl,
              vendorLogo,
              requiredFeatures,
              group,
              github,
            } = feature;

            return {
              ...result,
              [xmlId]: {
                id: xmlId,
                name,
                description,
                version,
                vcsLink: github,
                vendor: {
                  name: vendor,
                  url: vendorUrl,
                  logo: vendorLogo && backendAssetUrl(vendorLogo),
                },
                requiredFeatures,
                group,
              },
            };
          }, {}),
      );
      const resSelectedFeatureIds = Object.keys(resFeatures);

      return {
        ...state,
        selectedFeatureId: '',
        featuresLoaded: true,
        features: resFeatures,
        projectConfig: {
          ...state.projectConfig,
          plugins: state.projectConfig.plugins.filter((pluginId) =>
            resSelectedFeatureIds.includes(pluginId),
          ),
        },
      };
    }

    case UPDATE_LOCATION: {
      return {
        ...state,
        initialQuery: payload.queryParams,
        setParams: payload.setParams,
      };
    }

    case UPDATE_SERIALIZATION_MODEL: {
      return {
        ...state,
        serializationModel: payload,
      };
    }

    case LOAD_FEATURE_INFO: {
      return {
        ...state,
        docs: {
          ...state.docs,
          [payload.id]: payload.data,
        },
      };
    }

    case LOAD_FEATURE_DESCRIPTOR: {
      const {
        id,
        name,
        short_description,
        vendor,
        required_feature_ids,
        group,
        github,
        documentation,
      } = payload.data;

      const descriptor = {
        id,
        name,
        description: short_description,
        vcsLink: github,
        vendor,
        requiredFeatures: required_feature_ids,
        group,
        documentation,
      };

      return {
        ...state,
        descriptors: {
          ...state.descriptors,
          [id]: descriptor,
        },
      };
    }

    case SHOW_FEATURE_INFO: {
      return {
        ...state,
        selectedFeatureId: action.payload?.id,
      };
    }

    case SWITCH_PARAMETERS_MODE: {
      return {
        ...state,
        parametersMode: state.parametersMode === 'view' ? 'edit' : 'view',
      };
    }

    case CHANGE_PARAMETER_VALUE: {
      const paramName = action.payload.name;
      const oldParamValue = state.projectConfig[paramName];
      const newParamValue = action.payload.value;
      if (oldParamValue === newParamValue) {
        return state;
      }
      return {
        ...state,
        projectConfig: {
          ...state.projectConfig,
          [paramName]: newParamValue,
        },
        generatedProject: {},
      };
    }

    case FINISH_PROJECT_PREVIEW: {
      if (!payload) {
        return {
          ...state,
          stage: 'preview',
        };
      } else {
        const { files, transcript } = payload;
        return {
          ...state,
          stage: 'preview',
          previewProject: files,
          transcript,
        };
      }
    }

    case FINISH_PROJECT_GENERATING: {
      return {
        ...state,
        generatedProject: {
          blob: payload.blob,
          fileName: payload.fileName,
        },
      };
    }

    case HIDE_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter(
            (alert) => alert.key !== action.payload.alert.key,
        ),
      };
    }

    case SHOW_ALERT: {
      return {
        ...state,
        alerts: state.alerts.concat([action.payload.alert]),
      };
    }

    case SEARCH: {
      return {
        ...state,
        search: { ...payload },
      };
    }

    case TOGGLE_THEME: {
      const theme = state.theme;
      return {
        ...state,
        theme: theme === 'dark' ? 'light' : 'dark',
      };
    }

    case ADD_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.map((template) => ({
          ...template,
          added: template.added || template.id === payload.id,
        })),
      };
    }

    case REMOVE_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.map((template) => ({
          ...template,
          added: template.id === payload.id ? false : template.added,
        })),
      };
    }

    default:
      return state;
  }
}
