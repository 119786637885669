import cn from 'classnames';
import ProjectForm from './ProjectForm';
import { Button } from '@rescui/button';
import { DownloadIcon, InfoIcon } from '@rescui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useCardCn } from '@rescui/card';
import PropTypes from 'prop-types';
import { START_PROJECT_GENERATING } from '../redux/actionTypes';
import { connect } from 'react-redux';
import { useTextStyles } from '@rescui/typography';
import { Tooltip } from '@rescui/tooltip';

const GenerateCard = ({
  projectName,
  flatten,
  squeeze,
  onDownload,
  onGenerate,
  generatedProject,
  tooltip,
}) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const cardCn = useCardCn();
  const textCn = useTextStyles();

  const onStartGenerating = useCallback(() => {
    if (!isGenerating) {
      setIsGenerating(true);
      onDownload();
    }
  }, [onDownload, isGenerating]);

  useEffect(() => {
    if (isGenerating && generatedProject.blob) {
      setIsGenerating(false);
      onGenerate();
    }
  }, [isGenerating, generatedProject]);

  return (
    <div className={cn('control-panel__form', cardCn(), { flatten, squeeze })}>
      <ProjectForm flatten={flatten || squeeze} />

      <div className="control-panel__separator"></div>

      <div className="control-panel__download">
        <Button
          size={ squeeze ? 'm' : 'l' }
          theme="dark"
          icon={<DownloadIcon />}
          iconPosition="right"
          onClick={onStartGenerating}
          busy={isGenerating}
        >
          { 'Download' }
        </Button>
        {
          tooltip ?
            (
              <Tooltip content={
                <div style={{ padding: '8px 20px' }}>
                  <h4>New Ktor Project</h4>
                  <p>{tooltip}</p>
                </div>
              }>
                <Button
                  className="control-panel__download-info"
                  size="s"
                  mode="clear"
                  icon={<InfoIcon />}
                >
              About
                </Button>
              </Tooltip>
            ) : (
              <div className={cn('control-panel__download-info', textCn('rs-text-3', { 'hardness': 'hard' }))}>
                {projectName}.zip
              </div>
            )
        }
      </div>
    </div>
  );
};

GenerateCard.propTypes = {
  flatten: PropTypes.bool,
  squeeze: PropTypes.bool,
  projectName: PropTypes.string,
  onDownload: PropTypes.func,
  onGenerate: PropTypes.func,
  generatedProject: PropTypes.object,
  tooltip: PropTypes.object,
};


const mapDispatchToProps = (dispatch) => ({
  onDownload: () => dispatch({ type: START_PROJECT_GENERATING }),
});

const mapStateToProps = ({ projectConfig, generatedProject }) => ({
  projectName: projectConfig.name,
  generatedProject: generatedProject,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCard);
