import localStorage from '../constants/localStorage';

const BACKEND_BASE_URL = getBackendBaseUrl();

function getBackendBaseUrl() {
  const defaultBaseUrl = '';

  return localStorage.getItem('api') || defaultBaseUrl;
}

export function backendAssetUrl(path) {
  return `${BACKEND_BASE_URL}/assets/${path}`;
}

export default function fetchApi(
    url,
    options = undefined,
    parseResponseAs = 'json',
) {
  return fetch(`${BACKEND_BASE_URL}${url}`, options).then((resp) => {
    if (resp.ok) {
      return resp[parseResponseAs]();
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      message: resp.statusText || 'Something went wrong',
      status: resp.status,
    });
  });
}
